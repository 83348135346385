import { combineReducers } from 'redux';

// import AplicationReducer from './AplicationReducer';
import UsuarioReducer from './UsuarioReducer';
// import ServicoReducer from './ServicoReducer'

export default combineReducers({
	// AplicationReducer,
	UsuarioReducer,
	// ServicoReducer
});